<script setup>
import {useAccountStore} from "@shared/stores/AccountStore"

const accountStore = useAccountStore()
</script>

<template>
    <div id="categories">
        <nav>
            <router-link to="/webplay/all" class="catnav">
                <img src="/images/navigationMenuIcons/all-games.svg" alt="All Games"/>
                <span>All Games</span>
            </router-link>

            <router-link to="/webplay/live-casino" class="catnav">
                <img src="/images/navigationMenuIcons/live-dealer.svg" alt="Live Casino"/>
                <span>Live Casino</span>
            </router-link>

            <router-link to="/webplay/new-games" class="catnav">
                <img src="/images/navigationMenuIcons/new-games.svg" alt="New Games"/>
                <span>New Games</span>
            </router-link>

            <router-link to="/webplay/top-games" class="catnav">
                <img src="/images/navigationMenuIcons/top-games.svg" />
                <span>Top Games</span>
            </router-link>

            <router-link to="/webplay/category/slots" class="catnav">
                <img src="/images/navigationMenuIcons/slots.svg" alt="Slot Games"/>
                <span>Slot Games</span>
            </router-link>

            <router-link to="/webplay/category/table-games" class="catnav">
                <img src="/images/navigationMenuIcons/blackjack.svg" alt="Table Games"/>
                <span>Table Games</span>
            </router-link>

            <router-link to="/webplay/game-providers" class="catnav">
                <img src="/images/navigationMenuIcons/providers.svg" alt="Game Providers"/>
                <span>Game Providers</span>
            </router-link>

            <router-link to="/webplay/favourites" class="catnav" :class="{ 'favorites': !accountStore.isAuthenticated}">
                <img src="/images/navigationMenuIcons/favorite.svg" alt="Favorites"/>
                <span>Favorites</span>
            </router-link>
        </nav>
    </div>
</template>

<style>
    #webplay #categories {
        padding: 1em;

        nav .catnav.router-link-active {
            background-color: #102A23 !important;
        }
    }
</style>
