<script setup>
import 'vue3-carousel/dist/carousel.css'

import AppMenu from "@shared/components/molecules/AppMenu.vue"
import HeaderAccount from "@shared/components/molecules/HeaderAccount.vue"
import LoaderIndicator from "@shared/components/molecules/LoaderIndicator.vue"
import {defineAsyncComponent, onMounted, watch} from "vue"

const AlertModal = defineAsyncComponent(() => import("@shared/components/modals/AlertModal.vue"))
const AppVersionModal = defineAsyncComponent(() => import("@shared/components/modals/AppVersionModal.vue"))
const AuthModal = defineAsyncComponent(() => import("@shared/components/modals/AuthModal.vue"))
const CouponRedeemModal = defineAsyncComponent(() => import("@shared/components/modals/CouponRedeemModal.vue"))
const GamePlayModal = defineAsyncComponent(() => import("@shared/components/modals/GamePlayModal.vue"))
const HeaderNotifications = defineAsyncComponent(() => import("@shared/components/molecules/HeaderNotifications.vue"))
const HeaderWallet = defineAsyncComponent(() => import("@shared/components/molecules/HeaderWallet.vue"))
const LoyaltyChestModal = defineAsyncComponent(() => import("@shared/components/modals/LoyaltyChestModal.vue"))
const NotificationsModal = defineAsyncComponent(() => import("@shared/components/modals/NotificationsModal.vue"))
const ProfileEditModal = defineAsyncComponent(() => import("@shared/components/modals/ProfileEditModal.vue"))
const TermsAndConditionsModal = defineAsyncComponent(() => import("@shared/components/modals/TermsAndConditionsModal.vue"))
const TournamentsModal = defineAsyncComponent(() => import("@shared/components/modals/TournamentsModal.vue"))
const TransactionsModal = defineAsyncComponent(() => import("@shared/components/modals/TransactionsModal.vue"))
const WalletModal = defineAsyncComponent(() => import("@shared/components/modals/WalletModal.vue"))
const WageringModal = defineAsyncComponent(() => import("@shared/components/modals/WageringModal.vue"))

import AppFooter from "@shared/components/molecules/AppFooter.vue"
import {useAccountStore} from "@shared/stores/AccountStore"
import {useAffiliateStore} from "@shared/stores/AffiliateStore"
import {useGamePlayStore} from "@shared/stores/GamePlayStore"
import {useLoyaltyChestStore} from "@shared/stores/LoyaltyChestStore"
import {useNotificationsStore} from "@shared/stores/NotificationsStore"
import {useProvidersStore} from "@shared/stores/ProvidersStore"
import {useRoute} from "vue-router"

const accountStore = useAccountStore()
const affiliateStore = useAffiliateStore()
const gamePlayStore = useGamePlayStore()
const loyaltyChestStore = useLoyaltyChestStore()
const notificationsStore = useNotificationsStore()
const providersStore = useProvidersStore()

const route = useRoute()

onMounted(function () {
    accountStore.fetchUser()
    providersStore.getProviders()

    // eslint-disable-next-line no-undef
    if (useLiveChat && liveChatLicense) {
        window.__lc = window.__lc || {}
        // eslint-disable-next-line no-undef
        window.__lc.license = liveChatLicense

        const chatBadgeScript = document.createElement('script')
        chatBadgeScript.setAttribute('src', `//cdn.livechatinc.com/qb/qb-${liveChatLicense}-dark-200.js`)
        document.head.appendChild(chatBadgeScript)

        const chatScript = document.createElement('script')
        chatScript.setAttribute('src', '//cdn.livechatinc.com/tracking.js')
        document.head.appendChild(chatScript)
    }

    const urlSearchParams = new URLSearchParams(window.location.search)
    const gameToPlay = urlSearchParams.get('play')
    if(gameToPlay) {
        gamePlayStore.playGame(gameToPlay)
    }
})

watch(
    () => route.query,
    ({affid, code}) => {
        affiliateStore.setAffiliate(affid || code)
    }
)

</script>

<template>
    <div id="divAppHeader">

        <AlertModal
            v-if="notificationsStore.alert"
            :message="notificationsStore.alert"
            @click:confirm="notificationsStore.hideAlert"
        />

        <AppVersionModal />
        <AuthModal />
        <CouponRedeemModal />
        <GamePlayModal />
        <LoyaltyChestModal v-if="appHasLoyalty"/>
        <NotificationsModal />
        <ProfileEditModal />
        <WageringModal />
        <WalletModal />
        <TermsAndConditionsModal />
        <TournamentsModal />
        <TransactionsModal />

        <LoaderIndicator />

        <AppMenu />
        <HeaderWallet />

        <template v-if="accountStore.isAuthenticated">
            <div id="divHeaderPromotions" >
                <a href="/promotions">
                    <div class="divPromotionsIcon"></div>
                </a>
            </div>
            <div v-if="appHasLoyalty" id="divHeaderLoyaltyChest" class="show"
                 @click="() => loyaltyChestStore.setLoyaltyChestModalVisible(true)">
                <div class="divLoyaltyChestIcon"></div>
            </div>
            <HeaderNotifications />
            <HeaderAccount />
        </template>
    </div>
    <div id="divAppContentMenu"></div>

    <article>
        <slot></slot>
    </article>

    <AppFooter />
</template>

<style>
</style>
